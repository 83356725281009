<template>
  <div class="line-items-summary">
    <section class="line-items-summary__title">
      <div class="icon-container" v-if="limitsError">
        <div class="icon-container__content">
          <i class="ion ion-close-outline"></i>
        </div>
      </div>
      <h3>Totals</h3>
    </section>
    <hr />
    <section class="line-items-summary__info" v-loading="loading">
      <div :class="errorLimitsLineItem['units'] ? 'error-style' : ''">
        <strong>Total Units:</strong> {{ lineItemsCalculatedInfo.total_units }}
      </div>
      <div :class="errorLimitsLineItem['weight'] ? 'error-style' : ''">
        <strong>Total Weight:</strong> {{ lineItemsCalculatedInfo.total_weight }}
      </div>
      <div v-if="isTenderView || isFtl">
        <strong>Total Volume:</strong> {{ lineItemsCalculatedInfo.total_volume }}
      </div>
      <div
        :class="errorLimitsLineItem['ltf'] ? 'error-style' : ''"
        v-if="isTenderView || (isFtl && lineItemsCalculatedInfo.total_linear_feet)"
      >
        <strong>Total Linear:</strong> {{ lineItemsCalculatedInfo.total_linear_feet }} ft
      </div>
      <div v-if="isTenderView || isFtl">
        <strong>Density:</strong> {{ lineItemsCalculatedInfo.density }}
      </div>
    </section>
  </div>
</template>

<script>
import { convertWeightMeasure } from "@/Extend/ConvertToDefaultValues";
import { mapGetters } from "vuex";
import modes from "../views/QuoteBook/modes";
import lineItemsDimensionsSchema from "../schemas/lineItemsDimensionsSchema";

export default {
  props: {
    quoteMode: String,
    tenderMode: String,
    isTenderView: Boolean,
  },
  data() {
    return {
      loading: false,
      lineItemsDimensions: [],
      lineItemsDimensionsAux: [],
      stackable: false,
      lineItemsCalculatedInfo: {
        total_units: "0",
        total_weight: "0",
        total_volume: "0",
        total_linear_feet: "0",
        density: "0",
      },
      errorLimitsLineItem: {
        weight: false,
        units: false,
        ltf: false,
      },
      limitsError: false,
      modeId: null,
    };
  },
  created() {
    if (this.tenderMode) {
      this.modeId = this.tenderMode;
    }
  },
  computed: {
    ...mapGetters({
      vuexLineItems: "load/lineItems/getSelectedLineItems",
      accessorials: "load/accessorials/getChosenAccessorials",
      lineItemStackableInfo: "load/lineItems/getLineItemsResumeInfo",
    }),
    isFtl() {
      return this.$route.params.quoteType !== "ftl" && this.$route.params.quoteType !== "reefer";
    },
  },
  watch: {
    "$route.params": {
      handler() {
        if (this.$route.params.quoteType) {
          this.modeId = modes[this.$route.params.quoteType].modeId;
        }
      },
      immediate: true,
    },
    vuexLineItems: {
      handler(val) {
        const lineItemsDimensions = val.map((item) => ({
          handling_unit_count: parseInt(item.handling_unit_count, 10),
          weight: parseInt(item.weight, 10),
          width: parseInt(item.width, 10),
          height: parseInt(item.height, 10),
          length: parseInt(item.length, 10),
        }));
        this.buildLineItemsSchema(lineItemsDimensions);
        if (this.validateLineItemsInfo()) {
          this.calculateLineItemsInfo();
        }
      },
      deep: true,
    },
    lineItemStackableInfo: {
      deep: true,
      handler(val) {
        this.loading = true;
        this.lineItemsCalculatedInfo = val;
        this.loading = false;
      },
    },
    accessorials: {
      handler(accessorials) {
        const isStackable = accessorials.find((accessorial) => accessorial.id === 41);
        this.stackable = !!isStackable;
      },
      immediate: true,
    },
    lineItemsCalculatedInfo: {
      deep: true,
      handler() {
        this.$store.commit("load/lineItems/resetLineItemsLimitMessage");
        const totalWeightValidation = this.validateTotalWeightLimit(this.quoteMode);
        const totalUnitsValidation = this.validateMaxUnitsLimit(this.quoteMode);
        const totalLFTValidation = this.validateMaxLFT(this.quoteMode);
        if (totalWeightValidation || totalUnitsValidation || totalLFTValidation) {
          this.limitsError = true;
          this.$store.commit("load/lineItems/setLineItemsLimitValidation", false);
        } else {
          this.limitsError = false;
          this.$store.commit("load/lineItems/setLineItemsLimitValidation", true);
        }
      },
    },
  },
  methods: {
    lineItemsResume() {},
    buildLineItemsSchema(items) {
      /* eslint-disable */
      this.lineItemsDimensions = items.filter((element) =>
        lineItemsDimensionsSchema.isValidSync(element)
      );
    },
    validateLineItemsInfo() {
      return this.lineItemsDimensions.every((lineItem) => {
        return (
          lineItem?.handling_unit_count &&
          lineItem.height &&
          lineItem.length &&
          lineItem.weight &&
          lineItem.width
        );
      });
    },
    async calculateLineItemsInfo() {
      if (!this.lineItemsDimensions.length) {
        return;
      }
      if (
        JSON.stringify(this.lineItemsDimensions) === JSON.stringify(this.lineItemsDimensionsAux)
      ) {
        return;
      }
      this.loading = true;
      this.$store.commit("load/tender/setRequestInProcess", true);
      const payload = {
        body: {
          mode: this.modeId,
          stackable: this.stackable,
          line_items: this.lineItemsDimensions,
        },
        params: {
          include_lf: true,
        },
      };
      if ((this.quoteMode === "reefer" || this.quoteMode === "ftl") && !this.isTenderView) {
        payload.params.include_lf = false;
      }
      this.$store.commit("load/setUpdatingRequestInProcess", true);
      this.lineItemsCalculatedInfo = await this.$store.dispatch(
        "standardizers/calculateLineItemsInfo",
        payload
      );
      this.$store.commit("load/setUpdatingRequestInProcess", false);
      this.$store.commit("load/tender/setRequestInProcess", false);
      this.lineItemsDimensionsAux = this.$deepCopy(this.lineItemsDimensions);
      this.loading = false;
    },
    validateTotalWeightLimit(quoteMode) {
      let validation = false;
      let numberMax;
      switch (quoteMode) {
        case "all-options":
          numberMax = 46000;
          validation = parseInt(this.lineItemsCalculatedInfo.total_weight, 10) > numberMax;
          break;
        case "ltl":
          numberMax = 28000;
          validation = parseInt(this.lineItemsCalculatedInfo.total_weight, 10) > numberMax;
          break;
        case "expedited":
          numberMax = 9800;
          validation = parseInt(this.lineItemsCalculatedInfo.total_weight, 10) > numberMax;
          break;
        case "flatbed":
          numberMax = 46000;
          validation = parseInt(this.lineItemsCalculatedInfo.total_weight, 10) > numberMax;
          break;
        case "ftl":
          numberMax = 44000;
          validation = parseInt(this.lineItemsCalculatedInfo.total_weight, 10) > numberMax;
          break;
        case "reefer":
          numberMax = 43500;
          validation = parseInt(this.lineItemsCalculatedInfo.total_weight, 10) > numberMax;
          break;
        default:
          validation = false;
          break;
      }
      this.errorLimitsLineItem.weight = validation;
      if (validation) {
        const formattedNumberMax = numberMax.toLocaleString();
        const limitResult = convertWeightMeasure(
          this.vuexLineItems[0].user_weight_units,
          numberMax
        );
        const message = `Exceeded the weight limit. The total weight allowed is ${limitResult}(${this.vuexLineItems[0].user_weight_units}).`;
        this.$store.commit("load/lineItems/setLineItemsLimitMessages", message);
      }
      return validation;
    },
    validateMaxUnitsLimit(quoteMode) {
      let validation = false;
      let numberMax;
      switch (quoteMode) {
        case "all-options":
          validation = false; // N/A;
          break;
        case "ltl":
          numberMax = 30;
          validation = parseInt(this.lineItemsCalculatedInfo.total_units, 10) > numberMax;
          break;
        case "expedited":
          numberMax = 12;
          validation = parseInt(this.lineItemsCalculatedInfo.total_units, 10) > numberMax;
          break;
        case "flatbed":
          validation = false; // N/A
          break;
        case "ftl":
          validation = false; // N/A
          break;
        case "reefer":
          numberMax = 30;
          validation = parseInt(this.lineItemsCalculatedInfo.total_units, 10) > numberMax;
          break;
        default:
          validation = false;
          break;
      }
      this.errorLimitsLineItem.units = validation;
      if (validation) {
        const message = `You have exceeded the number of units limit for this service. The maximum  number of units  allowed is ${numberMax}.`;
        this.$store.commit("load/lineItems/setLineItemsLimitMessages", message);
      }
      return validation;
    },
    validateMaxLFT(quoteMode) {
      let validation = false;
      let numberMax;
      switch (quoteMode) {
        case "all-options":
          numberMax = 53;
          validation = parseInt(this.lineItemsCalculatedInfo.total_linear_feet, 10) > numberMax;
          break;
        case "ltl":
          numberMax = 28;
          validation = parseInt(this.lineItemsCalculatedInfo.total_linear_feet, 10) > numberMax;
          break;
        case "expedited":
          numberMax = 26;
          validation = parseInt(this.lineItemsCalculatedInfo.total_linear_feet, 10) > numberMax;
          break;
        case "flatbed":
          numberMax = 53;
          validation = parseInt(this.lineItemsCalculatedInfo.total_linear_feet, 10) > numberMax;
          break;
        case "ftl":
          numberMax = 53;
          validation = parseInt(this.lineItemsCalculatedInfo.total_linear_feet, 10) > numberMax;
          break;
        case "reefer":
          numberMax = 53;
          validation = parseInt(this.lineItemsCalculatedInfo.total_linear_feet, 10) > numberMax;
          break;
        default:
          validation = false;
          break;
      }
      this.errorLimitsLineItem.ltf = validation;
      if (validation) {
        const message = `You have exceeded the linear feat limit for this service. The maximum of linear feat allowed is ${numberMax}.`;
        this.$store.commit("load/lineItems/setLineItemsLimitMessages", message);
      }
      return validation;
    },
  },
};
</script>

<style lang="scss" scoped>
.line-items-summary {
  text-align: left;
  padding: 20px 0px;
  color: $color-primary-company;
  hr {
    margin-top: 5px;
    background-color: $color-border-container;
  }
  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    h3 {
      margin-bottom: 0px;
      font-size: 20px;
      font-weight: 600;
      @media (width <= 500px) {
        font-size: 18px;
      }
    }
  }
  &__info {
    display: flex;
    gap: 20px;
    min-height: 25px;
    @media (width <= 500px) {
      flex-direction: column;
    }
  }
}
.error-style {
  color: red;
}

.icon-container {
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #e9554c;
    i {
      font-size: 22px;
      color: white;
    }
  }
}

::v-deep {
  .el-loading-mask {
    background-color: rgba(239, 239, 239, 0.9);
  }
}
</style>
